import request from '@/utils/request'

// 添加服务内容模板
export function addService(data) {
  return request('post', '/aps/service/template/create', data)
}
// 修改服务内容模板
export function editService(data) {
    return request('post', '/aps/service/template/update', data)
  }
// 查询服务内容模板列表
export function serviceList(data) {
    return request('post', '/aps/service/template/list', data)
  }
// 查询服务内容模板详情
export function serviceInfo(data) {
    return request('post', '/aps/service/template/info', data)
  }